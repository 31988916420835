/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "DS.Customers-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.Orders-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.CustomersCards-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.Products-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.Reps-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.QBNotifications-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.Idempotency-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.HcpOrders-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "DS.HcpDetails-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "CXCUstomersAPI",
            "endpoint": "https://6ekrg5s5c9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXShipping",
            "endpoint": "https://mydmym4rw5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXPaymentsAPI",
            "endpoint": "https://w2340kl9se.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXProductsAPI",
            "endpoint": "https://hvlfnftc69.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXWarehouseOrderConfirmationAPI",
            "endpoint": "https://kn4zqemk9h.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXAccountingAPI",
            "endpoint": "https://w93nkfbb4c.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXRepsAPI",
            "endpoint": "https://200xpyqx46.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXOrdersAPI",
            "endpoint": "https://11kxbog41j.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXQuickbooksWebhookAPI",
            "endpoint": "https://krmdqqddvh.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "CXHcpOrdersAPI",
            "endpoint": "https://h663tzrhq5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:ce2088d3-7118-4fe4-8e1d-6433bba8f30c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6U2PRjjhd",
    "aws_user_pools_web_client_id": "6jlgtte04qqum50tkdltum5jk6",
    "oauth": {
        "domain": "covidxpress.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "FAMILY_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
